/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import ClickableText from '../common/ClickableText';
import LoginForm from './LoginForm';
import { LoginContext } from './LoginProvider';
import SSOLogin from './SSOLogin';
import './style.scss';
import * as config from './utils/interface';

const LoginFormWrapper: React.FC = () => {
  const { step, ssoConfig } = useContext(LoginContext);
  const [loginFormExpanded, setLoginFormExpanded] = useState(false);

  return (
    <div className="login-wrapper">
      {(step !== config.PASSWORD_INPUT || !ssoConfig) && <LoginForm />}

      {step === config.PASSWORD_INPUT && ssoConfig && !loginFormExpanded && (
        <SSOLogin />
      )}

      {step === config.PASSWORD_INPUT && ssoConfig && (
        <div
          className={`secondary-login-form form-${
            loginFormExpanded ? 'expanded' : 'collapsed'
          }`}
        >
          {!loginFormExpanded && (
            <div style={{ marginTop: '2rem' }}>
              <ClickableText
                title="Sign in with email and password"
                action={() => setLoginFormExpanded(!loginFormExpanded)}
              />
            </div>
          )}

          <div className="content">
            <LoginForm />
          </div>

          {loginFormExpanded && (
            <ClickableText
              title="Sign in with SSO"
              action={() => setLoginFormExpanded(!loginFormExpanded)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LoginFormWrapper;
