import { Input } from '@cuttingroom/core-components';
import React from 'react';
import * as config from '../utils/interface';
import { getTenantInfoFromLocalStorage } from '../utils/utility';

interface WorkspaceInputProps {
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
}

const WorkspaceInput: React.FC<WorkspaceInputProps> = ({
  workspace,
  setWorkspace,
}) => {
  const { tenantList } = getTenantInfoFromLocalStorage(
    localStorage.getItem(config.TENANT_INFO) || '{}',
  );

  return (
    <Input
      className="workspace dark"
      label="Your CuttingRoom"
      type="text"
      value={workspace}
      options={tenantList}
      clearable={false}
      showOptionsOnFocus
      onChange={(e: { target: { value: string } }) => setWorkspace(e.target.value)}
    />
  );
};

export default WorkspaceInput;
