import QRCode from 'qrcode';
import React from 'react';
import * as request from '../../../use/universalRequests';
import * as config from './interface';

const { REACT_APP_API_BASE_URL = '', REACT_APP_DOMAIN_NAME = '' } = process.env;

export const generateRedirectUrl = (
  origin: string,
  tenant: string,
  environment: string,
) => {
  const prefix = origin.includes('beta') ? `${tenant}.beta` : tenant;
  return `https://${prefix}.${environment}.${REACT_APP_DOMAIN_NAME}`;
};

export const getTenantInfoFromLocalStorage = (tenantInfoFromLocal: string) => {
  const tenantInfo: config.TenantInfoInterface = JSON.parse(
    tenantInfoFromLocal || '{}',
  );
  const { lastTenant = '', tenantList = [] } = tenantInfo;
  return { lastTenant, tenantList };
};

const updateTenantInfoToLocalStorage = (tenant: string) => {
  const { tenantList } = getTenantInfoFromLocalStorage(
    localStorage.getItem(config.TENANT_INFO) || '{}',
  );
  localStorage.setItem(
    config.TENANT_INFO,
    JSON.stringify({
      lastTenant: tenant,
      tenantList: [...new Set([...tenantList, tenant])],
    }),
  );
};

export const checkSso = async (
  payload: config.CheckSSOPayload,
  methods: config.CheckSSOMethods,
) => {
  const { email, tenant } = payload;
  const { setStep, setLoading, setSsoConfig } = methods;

  setLoading(true);
  const url = `${REACT_APP_API_BASE_URL}/auth/sso-details/?email=${email}${
    tenant && `&tenant=${tenant}`
  }`;

  const response = await request.universalGetRequest<config.CheckSSOResponse>(
    encodeURI(url),
  );
  if (response && response.data && response.status === 200) {
    const { ssoConfig } = response.data;
    setStep(config.PASSWORD_INPUT);
    setSsoConfig(response.data.ssoConfig);

    localStorage.setItem(config.SSO_CONFIG, JSON.stringify(ssoConfig));
    if (tenant) updateTenantInfoToLocalStorage(tenant);
  }

  setLoading(false);
};

export const authenticate = async (
  payload: config.AuthenticatePayload,
  methods: config.AuthenticateMethods,
) => {
  const { email, password } = payload;
  const { setStep, setLoading, setSecretCode, setErrorMessage } = methods;
  setLoading(true);

  if (email.length && password.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate`;

    const response = await request.universalPostRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticatePayload
    >(url, payload);
    if (response && response.data && response.status === 200) {
      const { envName, tenant, accessToken } = response.data;

      updateTenantInfoToLocalStorage(tenant);
      localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

      const redirectUrl = `${generateRedirectUrl(
        window.location.origin,
        tenant,
        envName,
      )}?accessToken=${accessToken}`;

      window.location.replace(redirectUrl);
    } else {
      const { code = '', error = '', mfaSecretCode = '' } = response;
      setErrorMessage(error);

      if (code === config.MFA_CODE_MISSING) {
        setErrorMessage('');
        setStep(config.MFA_CODE_MISSING);
      } else if (code === config.NEW_PASSWORD_REQUIRED) {
        setErrorMessage('');
        setStep(config.NEW_PASSWORD_REQUIRED);
      } else if (code === config.MFA_SETUP_REQUIRED) {
        setStep(config.MFA_SETUP_REQUIRED);
        setSecretCode(mfaSecretCode);
      }
    }
  }
  setLoading(false);
};

export const authenticateMFA = async (
  payload: config.AuthenticateMFAPayload,
  methods: config.AuthenticateMFAMethods,
) => {
  const { email, password, mfaCode } = payload;
  const { setLoading, setErrorMessage } = methods;
  setLoading(true);

  if (email.length && password.length && mfaCode.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate/mfa`;

    const response = await request.universalPostRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticateMFAPayload
    >(url, payload);
    if (response && response.data && response.status === 200) {
      const { envName, tenant, accessToken } = response.data;

      updateTenantInfoToLocalStorage(tenant);
      localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

      const redirectUrl = `${generateRedirectUrl(
        window.location.origin,
        tenant,
        envName,
      )}?accessToken=${accessToken}`;

      window.location.replace(redirectUrl);
    } else {
      const { error = '' } = response;
      setErrorMessage(error);
    }
  }
  setLoading(false);
};

export const authenticatePassword = async (
  payload: config.AuthenticatePasswordPayload,
  methods: config.AuthenticatePasswordMethods,
) => {
  const { email, password, newPassword } = payload;
  const { setStep, setLoading, setErrorMessage, setSecretCode } = methods;
  setLoading(true);

  if (email.length && password.length && newPassword.length) {
    const url = `${REACT_APP_API_BASE_URL}/auth/authenticate/password`;

    const response = await request.universalPutRequest<
      config.AuthenticateResponseInterface,
      config.AuthenticatePasswordPayload
    >(url, payload);
    if (response && response.data && response.status === 200) {
      const { envName, tenant, accessToken } = response.data;

      updateTenantInfoToLocalStorage(tenant);
      localStorage.setItem(config.AUTH_INFO, JSON.stringify(response.data));

      const redirectUrl = `${generateRedirectUrl(
        window.location.origin,
        tenant,
        envName,
      )}?accessToken=${accessToken}`;

      window.location.replace(redirectUrl);
    } else {
      const { code = '', error = '', mfaSecretCode = '' } = response;
      setErrorMessage(error);
      if (code === config.MFA_SETUP_REQUIRED) {
        setErrorMessage('');
        setStep(config.MFA_SETUP_REQUIRED);
        setSecretCode(mfaSecretCode);
      }
    }
  }
  setLoading(false);
};

export const generateQRCodeUrl = (
  email: string,
  secretCode: string,
  setSrc: React.Dispatch<React.SetStateAction<string>>,
) => {
  QRCode.toDataURL(
    `otpauth://totp/${email}?secret=${secretCode}&issuer=${window.location.host}`,
  ).then(setSrc);
};
